import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SectionTitle, BaseButton } from "../../components/Section";
import ProductCard from "../../components/ProductCard";

const SectionWrap = styled.div`
	position: relative;
	padding: 100px 0 20px;
	background: linear-gradient(to bottom, #fff 100%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 0.01) 80%);

	@media(max-width: 480px) {
		padding-top: 80px;
		.container {
			position: relative;
			width: 100%;
		}
	}
`

const ProductListWrap = styled.div`
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 10px;
	@media(min-width:1200px){
		padding-bottom: 30px;
	}
	@media(min-width:1600px){
		padding-bottom: 40px;
	}
`

const LoadMoreButton = styled(BaseButton)`
  background: #D51333;
  color:#fff;
  border-radius: 6px;
  box-shadow: 0 6px 0 #982221;
  font-family: "Oswald", sans-serif;
  margin: auto;
  display: block;
  width: fit-content;

  &:after {		
    background: #A20000;
  }
  &:hover {
    cursor: pointer;
  }
`

const ProductSection = ({ title, data }) => {
	const [showLimit, setShowLimit] = useState(10);
	const [currentTab, setTab] = useState("All");
	const [currentProduct, setCurrentProduct] = useState(data);

	useEffect(() => {
		if (currentTab === "All") {
			setCurrentProduct(data)
		} else {
			let currentSubCategoryProduct = []
			data.forEach(item => {
				if (item.subCategory.name === currentTab) {
					currentSubCategoryProduct.push(item)
				}
			})
			setCurrentProduct(currentSubCategoryProduct);
		}

	},[currentTab, setTab])
	return(
		<SectionWrap>
			<div className="container">
				<SectionTitle>{title}</SectionTitle>
				<ProductListWrap>
					{currentProduct?.map((product, i) => i < showLimit && (
							<ProductCard data={product} key={`product-${i}`} />
						)
					)}
					{currentProduct.length > showLimit && (
						<LoadMoreButton onClick={()=>setShowLimit(showLimit + 10)}>Load More Buildings</LoadMoreButton>
					)}
				</ProductListWrap>
			</div>
		</SectionWrap>
	);
};

export default ProductSection;