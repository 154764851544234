import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BreadCrumb, SectionPageTitle, SectionTitle, SectionDescription } from "../components/Section"
import GrayWoodSection from "../components/Section/GrayWoodSection";
import Content, { HTMLContent } from '../components/Content';
import generateHTML from '../utils/generateHTML';
import HeroSection from "../sections/Category/HeroSection"
import ProductSection from "../sections/Category/ProductSection"
import AvailableSection from "../sections/Category/AvailableSection";
import CtaSection from '../sections/CtaSection';
import RoofStyle from "../components/RoofStyle";


const ColorTabs = styled.div`
  background: #F7F5F5;
  padding: 30px 0;
  @media(min-width: 1200px) {
  	padding: 40px 0;
  }
  @media(min-width: 1600px) {
  	padding: 60px 0;
  }
`

const ColorNavTabs = styled.div`
  display: flex;
  background: #F7F5F5;
  border: 1px solid #D0D0D0;
  border-radius: 40px;
  padding: 2px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  @media(min-width: 1200px) {
	margin-bottom: 30px;	
  }
`

const ColorNavitem = styled.div`
  position: relative;
  background: ${props => (props.active === "true" ? "#ffffff" : "#F7F5F5")};
  color: ${props => (props.active === "true" ? "#E43D3C" : "#938888")};
  box-shadow: ${props => (props.active === "true" ? "0 0 30px rgba(0, 0, 0, .16)" : "none")};
  border-radius: 40px;
  font-size: 20px;
  line-height: 1;
  padding: 10px 20px;
  margin-left: ${props => props.ml};

  :hover {
    cursor: pointer;
  }

  @media(max-width: 480px) {
    font-size: 14px;
    padding: 10px 15px;
  }
`

const ColorTabContent = styled.div`  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin:0 -10px;

`
const ColorItem = styled.div`	
	position:relative;
	width: 100%;	
	padding: 0 10px;
	margin-bottom: 30px;
	@media(min-width: 576px) {
		max-width:50%;
		flex:0 0 50%;
	}
	@media(min-width: 768px) {
		max-width:33.333333%;
		flex:0 0 33.333333%;
	}
	@media(min-width: 992px) {
		max-width:25%;
		flex:0 0 25%;
	}
`
const Color = styled.div`	
	background: #ffffff;
	text-align: center;
	padding: 0 10px;
	.gatsby-image-wrapper, img {
		width: 100%;
		height: 40px;
	}
	p {
		padding:8px 0;
		margin-bottom: 0;
		font-size: 12px;
		line-height: 1;
		@media(min-width: 576px) {
			font-size: 14px;
		}
	}
`


const CategoryLandingTemplate = ({ data, location }) => {
	const [colorOption, setColorOption] = useState("siding");
	const pageData = data.contentfulCategoryLanding;
	//console.log(pageData ,"pageData")
	const PostContent = HTMLContent || Content;
	let currentCategoryProduct = []
	data.allContentfulProduct.edges.forEach(function (item) {
		if (item.node.category?.name === pageData.category.name) {
			currentCategoryProduct.push(item.node);
		}
	})
	let currentSubCategories = [];
	data.allContentfulSubCategory.edges.forEach(function (item) {
		if (item.node.category?.name === pageData.category.name) {
			currentSubCategories.push(item.node);
		}
	})
	const heroData = {
		title: pageData.heroTitle,
		subTitle: pageData.heroSubTitle,
		description: pageData.heroDescription,
		subCategories: currentSubCategories,
		category: pageData.category.name,
	}
	const availableSectionData = {
		title: pageData.availableSectionTitle,
		description: pageData.availableSectionDescription
	}
	const canViewRoofStyle = () => {
		switch (pageData.category.name) {
			case "Portable Buildings":
			case "Red Iron Buildings":
			case "Pole Barns":
			case "Gazebos":
			case "Greenhouses":
			case "Screen Rooms":
				return false;
			default:
				return true;
		}
	}

	return (
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<BreadCrumb position="relative">
				<div className="container"><Link to="/">Home</Link><span>&nbsp;/&nbsp;</span><span>{pageData.category.name}</span></div>
			</BreadCrumb>

			{pageData.noSubcategory ? (
				<GrayWoodSection>
					<div className="container">
						<SectionPageTitle>{pageData.heroTitle}</SectionPageTitle>
						<SectionDescription maxWidth="960px"><div dangerouslySetInnerHTML={{ __html: pageData.heroDescription.childMarkdownRemark.html }} /></SectionDescription>
					</div>
				</GrayWoodSection>
			) : (
				<HeroSection data={heroData} />
			)}

			<ProductSection
				title={pageData.productSectionTitle}
				subCategories={currentSubCategories}
				data={currentCategoryProduct}
			/>

			{pageData.availableSectionDescription && (
				<AvailableSection data={availableSectionData} />
			)}

			{canViewRoofStyle() && <RoofStyle />}

			{pageData.category.name !== "Red Iron Buildings" && (
				<CtaSection mb={pageData.content ? "0" : "50px"} />
			)}

			{pageData.category.name == "Red Iron Buildings" && pageData.sidingColors !== null && pageData.trimColors !== null && (

				<ColorTabs>
					<div className="container">
						<SectionTitle>Color Options</SectionTitle>
						<SectionDescription maxWidth="960px">Select one of our available paint colors as your primary building color or trim color.</SectionDescription>
						<ColorNavTabs>
							<ColorNavitem
								active={colorOption === "siding" ? "true" : "false"}
								onClick={() => setColorOption("siding")}
							>Siding Colors
							</ColorNavitem>
							<ColorNavitem
								active={colorOption === "trim" ? "true" : "false"}
								onClick={() => setColorOption("trim")}
								ml="20px"
							>
								Trim Colors
							</ColorNavitem>
						</ColorNavTabs>

						{colorOption === "siding" && (
							<ColorTabContent>
								{pageData.sidingColors.map((sc, i) => (
									<ColorItem key={`sidingColor-${i}`}>
										<Color>
											<GatsbyImage image={getImage(sc.color.gatsbyImageData)} alt={sc.name} />
											<p>{sc.name}</p>
										</Color>
									</ColorItem>
								))}
							</ColorTabContent>
						)}
						{colorOption === "trim" && (
							<ColorTabContent>
								{pageData.trimColors.map((tc, i) => (
									<ColorItem key={`trimcolor-${i}`}>
										<Color>
											<GatsbyImage image={getImage(tc.color.gatsbyImageData)} alt={tc.name} />
											<p>{tc.name}</p>
										</Color>
									</ColorItem>
								))}
							</ColorTabContent>
						)}
					</div>
				</ColorTabs>
			)}

			{pageData.content && (
				<GrayWoodSection mb="50px" maxWidth="960px">
					<div className="container"><PostContent content={generateHTML(pageData.content.childMarkdownRemark.html)} /></div>
				</GrayWoodSection>
			)}
		</Layout>
	)
}

export default CategoryLandingTemplate;

export const pageQuery = graphql`
  query CategoryLandingTemplateQuery($id: String!) {
    contentfulCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      category {
        name
      }
      noSubcategory
      heroTitle
	    heroSubTitle
	    heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    productSectionTitle
	    availableSectionTitle
	    availableSectionDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
			sidingColors {
				name
				color {
					gatsbyImageData(quality: 80)
				}
			}
			trimColors {
				name
				color {
					gatsbyImageData(quality: 80)
				}
			}	    
	    content {
	    	childMarkdownRemark {
	    		html
	    	}
	    }
    }
    allContentfulSubCategory {
	    edges {
	      node {
	        name
	        slug
	        visibleInCategoryLanding
	        category {
	          name
	          url
	        }
	        image {
	          gatsbyImageData(placeholder: BLURRED, quality: 50)
	        }
	      }
	    }
	  }
	  allContentfulProduct {
		edges {
			node {
			category {
				name
			}
			subCategory {
				name
			}
			name
			sku
			width
			length
			height
			image {
				gatsbyImageData(placeholder: BLURRED, quality: 50)
			}
			startingPrice
			downPayment
			url
			}
		}
    }
	contentfulProduct(id: { eq: $id }) {
		sidingColors {
		  name
		  color {
			gatsbyImageData(quality: 80)
		  }
		}
		trimColors {
		  name
		  color {
			gatsbyImageData(quality: 80)
		  }
		}
	}
  }
`